// extracted by mini-css-extract-plugin
export var HomePage = "HomePage-module--HomePage--2D5Gw";
export var profile_picture = "HomePage-module--profile_picture--1S_ua";
export var logo_container = "HomePage-module--logo_container--3MEMM";
export var contact_button_container = "HomePage-module--contact_button_container--lwpuo";
export var top_gradient = "HomePage-module--top_gradient--53zHa";
export var bottom_gradient = "HomePage-module--bottom_gradient--14PDI";
export var logo_area = "HomePage-module--logo_area--1NaJ3";
export var logo = "HomePage-module--logo--3AgoV";
export var top_area = "HomePage-module--top_area--ejd4f";
export var bottom_area = "HomePage-module--bottom_area--30WwK";
export var top_sphere = "HomePage-module--top_sphere--3qH9l";
export var bottom_sphere = "HomePage-module--bottom_sphere--oXvZu";
export var rotation = "HomePage-module--rotation--1iVqL";
export var body_wrapper = "HomePage-module--body_wrapper--2s5DN";
export var changeColor = "HomePage-module--changeColor--2HAfQ";
export var rotate = "HomePage-module--rotate--1wxHB";