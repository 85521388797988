import * as React from "react"
import {useState, useEffect} from "react";
import * as styles from './TextRoll.module.scss';
import Button from "../Button/Button";
import me from "../../images/me.png";

const TextRoll = (props) => {

    const [rolledUp, setRolledUp] = useState(false);
    const [textHeight, setTextHeight] = useState(0);

    useEffect(() => {
        let text1 = document.getElementById("text1");
        setTextHeight(text1.offsetHeight)
        function handleResize() {
            let text1 = document.getElementById("text1");
            setTextHeight(text1.offsetHeight)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize')
        };
    }, []);

    const handleContactClick = async () => {
        try {
            await navigator.clipboard.writeText("info@matyas.design");
            setRolledUp(true);
            setTimeout(() => setRolledUp(false), 3000);
        } catch (err) {
            console.error('Failed to copy: ', err);
        }
    };

    return(
        <div className={styles.TextRoll}>
            <img className={styles.profile_picture} src={me} alt=""/>
            <h6>Matyas Sunyovszki – Visual & Experience Designer in Munich</h6>
            <div className={`${styles.roller}`} style={{height: textHeight}}>
                <div className={`${styles.textHolder}`} style={rolledUp ? {transform: `translateY(-${textHeight}px)`} : {}} >
                    <h1 id="text1">This website is under development</h1>
                    <h1 id="text2">Email address copied to clipboard</h1>
                </div>
            </div>
            <div className={styles.contact_button_container}>
                <Button
                    id="Contact"
                    onClick={() => handleContactClick()}
                    kind="primary"
                    size="normal"
                    layout="stretch"
                    leftIcon="contact">
                        Contact
                </Button>
            </div>
        </div>
    );
};

export default TextRoll;