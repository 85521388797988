import * as React from "react";

import { useStaticQuery, graphql, Link } from "gatsby"

import * as styles from './HomePage.module.scss';
import Button from "../Button/Button";
import SEO from "../SEO/SEO";
import TextRoll from "../TextRoll/TextRoll";


import top_sphere from "../../images/top_sphere.jpg";
import bottom_sphere from "../../images/bottom_sphere.jpg";
import me from "../../images/me.png";
import useMousePosition from "./MousePosition";
import "../../css/global.scss"
import "../../css/variables.scss"
import "../../css/styles.scss"


import { useEffect, useState } from 'react';


const HomePage = (props) => {

    const [contentHeight, setContentHeight] = useState(0);
    const mousePosition = useMousePosition();
    const [vh, setVh] = useState(0);

    useEffect(() => {
        setVh(Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0))
        setContentHeight(document.getElementById("hero").offsetHeight)
        function handleResize() {
          setContentHeight(document.getElementById("hero").offsetHeight)
        }
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize')
        };
    }, []);

    return (
        <article className={styles.HomePage}>
            <SEO/>
            <div className={styles.top_gradient} style={{top : `${(vh-contentHeight)/2}px`}}/>
            <div className={styles.bottom_gradient} style={{bottom : `${(vh-contentHeight)/2}px`}}/>
            <div id="bodyWrapper" className={styles.body_wrapper}>
                <div className={styles.top_area} style={{height : `${(vh-contentHeight)/2}px`}}>
                    <img
                        className={styles.top_sphere}
                        style={{transform: `translate(${mousePosition.x / 10}px, ${mousePosition.y / 10}px)`}}
                        src={top_sphere}
                        alt=""/>
                </div>
                <section id="hero">
                    <TextRoll/>
                </section>
                <div
                    className={styles.bottom_area}
                    style={{height : `${(vh-contentHeight)/2}px`, transform: `translate(${mousePosition.x / 10}px, ${mousePosition.y / 10}px    )`}}>
                    <img
                        className={styles.bottom_sphere}
                        src={bottom_sphere}
                        alt=""/>
                </div>
            </div>
        </article>
    );
};



export default HomePage;
