import * as React from "react";

import {Helmet} from "react-helmet";


const SEO = (props) => {

    const default_title = "Matyas Sunyovszki";
    const default_description = "Matyas Sunyovszki is a UI/UX Designer based in Munich, Germany.";
    const default_social = "https://images.prismic.io/ksunyo/c18cf573-dc18-4d32-812b-e27689e5ca1a_cover.png";

    return (
        <Helmet>
            <title>{props.title !== undefined ? props.title : default_title}</title>
            <meta name="description" content={props.description !== undefined ? props.description : default_description}/>    

            <meta name="twitter:card" content="summary_large_image"/>
            <meta name="twitter:site" content="@ksunyo"/>
            <meta name="twitter:title" content={props.title !== undefined ? props.title : default_title}/>
            <meta name="twitter:description" content={props.description !== undefined ? props.description : default_description}/>
            <meta name="twitter:creator" content="@ksunyo"/>
            <meta name="twitter:image" content={props.share_img !== undefined ? props.share_img : default_social}/>
            
            <meta property="og:title" content={props.title !== undefined ? props.title : default_title} />
            <meta property="og:type" content="article" />
            <meta property="og:url" content="http://www.matyas.design/" />
            <meta property="og:image" content={props.share_img !== undefined ? props.share_img : default_social} />
            <meta property="og:description" content={props.description !== undefined ? props.description : default_description} /> 
        </Helmet>
    );
};

export default SEO;