import * as React from "react"
import HomePage from "../components/HomePage/HomePage"

const IndexPage = () => {

	return (
			<HomePage/>
	)
}

export default IndexPage
